<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h2>Brands</h2>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search Brands"
            v-model="searchTerm"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-btn
              class="mr-2"
              depressed
              light
              :to="{ name: 'module-eow-products' }"
            >
              <v-icon small left>mdi-arrow-left</v-icon> Back to Products
            </v-btn>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  color="accent"
                  class="mr-2"
                  v-on="on"
                  @click="$refs.brandForm.openForm()"
                >
                  <v-icon small>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Add New Brand</span>
            </v-tooltip>
          </v-row>
        </v-col>
      </v-row>

      <v-simple-table>
        <thead>
          <tr>
            <th
              v-for="header in tableHeaders"
              :key="header.text"
              :class="header.align === 'right' ? 'text-right' : ''"
            >
              {{ header.text }}
            </th>
          </tr>
        </thead>
        <draggable v-model="items" tag="tbody">
          <tr v-for="(item, index) in items" :key="index">
            <td>
              <v-icon small class="page__grab-icon">mdi-arrow-all</v-icon>
            </td>
            <td>
              <v-img
                v-if="item.thumbnail"
                width="100"
                :src="item.asset_urls.thumbnail"
                aspect-ratio="16/9"
                cover
                class="white--text align-end pb-4 pt-4"
                gradient="to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.5)"
              >
              </v-img>
            </td>
            <td>
              {{ item.name }}
            </td>
            <td class="text-right">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    x-small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    class="mr-2"
                    @click="$refs.brandForm.openForm(item)"
                  >
                    <v-icon x-small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    x-small
                    depressed
                    color="red lighten-4 red--text"
                    v-on="on"
                    @click="openDeleteBrand(item)"
                  >
                    <v-icon x-small>mdi-archive</v-icon>
                  </v-btn>
                </template>
                <span>Delete</span>
              </v-tooltip>
            </td>
          </tr>
        </draggable>
      </v-simple-table>
    </v-container>

    <BrandForm ref="brandForm" />
    <v-dialog v-model="deleteBrand.dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">Delete Brand</v-card-title>
        <v-card-text
          >Are you sure you wish to delete
          {{ deleteBrand.brand.name }}?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetDeleteBrand"
            >No, cancel</v-btn
          >
          <v-btn
            color="success"
            depressed
            :loading="deleteBrand.loading"
            @click="saveDeleteBrand"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import BrandForm from "./components/BrandForm";
import Draggable from "vuedraggable";

export default {
  components: {
    BrandForm,
    Draggable,
  },

  data() {
    return {
      searchTerm: "",
      breadcrumbs: [
        {
          text: "eow",
          disabled: false,
          to: { name: "app-dashboard", params: { id: this.$route.params.id } },
        },
        {
          text: "Products",
          disabled: false,
          exact: true,
          to: {
            name: "module-eow-products",
          },
        },
        {
          text: "Brands",
          disabled: true,
        },
      ],
      tableHeaders: [
        { text: "", sortable: false },
        { text: "Image", value: "thumbnail", width: "50px" },
        { text: "Name", value: "name" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
      deleteBrand: {
        dialog: false,
        brand: {},
        loading: false,
      },
    };
  },

  computed: {
    items: {
      get() {
        return this.brands;
      },
      set(items) {
        const displayOrder = items.map((p) => {
          return p.id;
        });
        const appId = this.$route.params.id;

        this.$store.commit("showHideLoader", true);
        this.$store
          .dispatch("eow/productsStore/updateBrandsDisplayOrder", {
            appId,
            displayOrder,
          })
          .then(() => this.$store.commit("showHideLoader", false))
          .catch(() => this.$store.commit("showHideLoader", false));
      },
    },
    brands() {
      let brands = this.$store.getters["eow/productsStore/brands"];

      if (this.searchTerm !== "") {
        brands = brands.filter((c) => {
          const name = c.name.toLowerCase();
          return name.includes(this.searchTerm.toLowerCase());
        });
      }

      return brands;
    },
  },

  methods: {
    openDeleteBrand(brand) {
      this.deleteBrand.brand = brand;
      this.deleteBrand.dialog = true;
    },

    resetDeleteBrand() {
      this.deleteBrand.dialog = false;
      this.deleteBrand.brand = {};
      this.deleteBrand.loading = false;
    },

    saveDeleteBrand() {
      this.deleteBrand.loading = true;

      this.$store
        .dispatch("eow/productsStore/deleteBrand", {
          appId: this.$route.params.id,
          brandId: this.deleteBrand.brand.id,
        })
        .then(() => {
          this.resetDeleteBrand();
        })
        .catch(() => {
          this.deleteBrand.loading = false;
        });
    },
  },
};
</script>
